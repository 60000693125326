<template>
    <div class="show-not-found background-not-supported">
        <div class="container-flex">
            <img :src="logoCaredokter" />
                <div
                    class="show-loading-not-support d-flex align-items-end justify-content-center"
                >
                    <div
                        class="spinner-grow spinner-grow-sm c-pink-care-doctor mr-2"
                        role="status"
                    >
                        <span class="sr-only"></span>
                    </div>
                    <div
                        class="spinner-grow spinner-grow-sm c-pink-care-doctor mr-2"
                        role="status"
                    >
                        <span class="sr-only"></span>
                    </div>
                    <div
                        class="spinner-grow spinner-grow-sm c-pink-care-doctor"
                        role="status"
                    >
                        <span class="sr-only"></span>
                    </div>
                </div>
                <label class="font-wait mt-2">Mohon tunggu</label>
                <label class="font-access"
                    >Kami sedang mendaftarkan account Anda<br />Proses ini dapat
                    memakan waktu hingga beberapa menit
                </label>
        </div>
    </div>
</template>


<script>
import axios from '@/axios'
import logoCaredokter from "@/assets/caredokter_horizontal.png";

export default {
    data () {
        return {
            logoCaredokter: `${logoCaredokter}`
        }
    },
     mounted () {
        const that = this
        setTimeout(async () => {
            const query = this.$route.query
            if(query.id && query.signatureKey) {
                await this.linkedPerujuk(query)
                    .then(_data => {
                        that.$router.push({name: 'home'}).catch(() => {})
                    })
                    .catch(() => {
                        that.$router.push({name: 'error'}).catch(() => {})
                    })
            }
        }, 1000)
    },
    methods: {
        linkedPerujuk(query) {
            return axios.post(`/api/registrasi-perujuk`,{
                ...query
                })
            },
    }
}
</script>

<style>

</style>